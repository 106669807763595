import axios from "axios";
import { local_backend_server } from "./config";
const REST_END_POINT = local_backend_server;
function baseAxios(options) {
  const defaultHeaders = {
    "Content-Type": "application/json",
    "Accept-Language": options.language ? options.language : "en",
    lang: options.lang ? options.lang : "en",
    ...options.extraHeaders,
  };

  const baseUrl = REST_END_POINT;
  return axios.create({
    baseURL: baseUrl,
    timeout: options.timeout || 30000,
    headers: defaultHeaders,
  });
}

function executeRequest(method, pathName, data, options = {}) {
  const body =
    method === "get" || !data
      ? {}
      : {
          data,
        };
  const reqObj = {
    method,
    url: pathName,
    params: options.query,
    ...body,
  };

  const baseAxiosRequest = baseAxios(options);
  return new Promise((resolve, reject) => {
    return baseAxiosRequest
      .request(reqObj)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log(REST_END_POINT);
        console.log("Axios", error.response);
        reject(
          error.response ? error.response.data : "Unhandled exception ocurred"
        );
      });
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get(pathName, options) {
    return executeRequest("get", pathName, null, options);
  },

  post(pathName, data, options) {
    return executeRequest("post", pathName, data, options);
  },

  put(pathName, data, options) {
    return executeRequest("put", pathName, data, options);
  },

  delete(pathName, data, options) {
    return executeRequest("delete", pathName, data, options);
  },

  all(promises) {
    return axios.all(promises);
  },
};
