import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import React, { useEffect, useRef, useState } from "react";
import { token } from "./accessToken";

// Styles
import "./MainMap.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = token;
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const MainMap = function MapInput({
  setCoords,
  coords,
  fields,
  activeField,
  isAddingEditing,
  mapBounds,
}) {
  const mapContainer = useRef();
  const map = useRef(null);
  const currentMarker = useRef(null);
  const [markers, setMarkers] = useState([]);
  const lastActiveField = usePrevious(activeField);

  const getLocationOrDefaultCoords = () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        () => {
          resolve({
            lat: coords.lat,
            lon: coords.lon,
          });
        }
      );
    });
  };

  const getMarkerElement = (text, imageName, id) => {
    const el = document.createElement("span");
    el.className = "marker";
    el.id = id;
    el.style.backgroundImage = "url(/" + imageName + ")";
    el.style.width = "40px";
    el.style.height = "40px";
    el.style.backgroundSize = "100%";
    if (id === "new-field-marker") el.style.zIndex = "9";
    if (text) {
      const elName = document.createElement("span");
      elName.className = "marker-text";
      elName.id = "marker-text-id";
      elName.innerHTML = text;
      el.appendChild(elName);
    }
    return el;
  };

  useEffect(async () => {
    const location = await getLocationOrDefaultCoords();
    const lat = location.lat.toFixed(4);
    const lon = location.lon.toFixed(4);
    setCoords({ lat, lon });
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [lon, lat],
      zoom: 6,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
    });
    map.current
      .addControl(geocoder, "top-left")
      .addControl(new mapboxgl.NavigationControl({ showCompass: false }))
      .addControl(new mapboxgl.GeolocateControl());

    geocoder.on("result", (ev) => {
      setCoords({
        lat: ev.result.center[1],
        lon: ev.result.center[0],
      });
    });

    currentMarker.current = new mapboxgl.Marker({
      draggable: true,
      element: getMarkerElement(
        "New Field",
        "new-field-marker.png",
        "new-field-marker"
      ),
    });
    currentMarker.current.setLngLat([lon, lat]);
    currentMarker.current.addTo(map.current);
    currentMarker.current.on("dragend", function (e) {
      setCoords({
        lat: e.target._lngLat.lat.toFixed(4),
        lon: e.target._lngLat.lng.toFixed(4),
      });
    });

    map.current.on("click", function (e) {
      setCoords({
        lat: e.lngLat.lat.toFixed(4),
        lon: e.lngLat.lng.toFixed(4),
      });
    });

    setTimeout(() => {
      map.current.resize();
      map.current.flyTo({
        zoom: 15,
        speed: 1,
        curve: 1,
      });
    }, 400);
  }, []);

  useEffect(() => {
    if (currentMarker.current && coords && coords.lon && coords.lat) {
      currentMarker.current.setLngLat([coords.lon, coords.lat]);
      if (activeField) {
        map.current.flyTo({
          zoom: 15,
          speed: 1,
          curve: 1,
          center: [coords.lon, coords.lat],
        });
      }
    }
  }, [coords]);

  useEffect(() => {
    markers.forEach((marker) => marker.remove());
    setMarkers(
      fields.map((field) => {
        // Create a DOM element for each marker.
        const current = new mapboxgl.Marker({
          element: getMarkerElement(
            field.name + " Field",
            "field-marker.png",
            field.id + "-id"
          ),
        });
        current.setLngLat([field.lon, field.lat]);
        current.addTo(map.current);
        return current;
      })
    );
  }, [fields]);

  useEffect(() => {
    if (map.current) map.current.fitBounds(mapBounds);
  }, [mapBounds]);

  useEffect(() => {
    if (isAddingEditing) {
      if (document.getElementById("new-field-marker")) {
        document.getElementById("new-field-marker").style.display = "block";
      }
      if (activeField) {
        if (document.getElementById("marker-text-id"))
          document.getElementById("marker-text-id").innerHTML = "Edit location";
        if (document.getElementById(activeField.id + "-id"))
          document.getElementById(activeField.id + "-id").style.display =
            "none";
      } else {
        if (document.getElementById("marker-text-id"))
          document.getElementById("marker-text-id").innerHTML = "New Field";
      }
    } else {
      if (document.getElementById("new-field-marker")) {
        document.getElementById("new-field-marker").style.display = "none";
      }
      if (lastActiveField) {
        if (document.getElementById(lastActiveField.id + "-id"))
          document.getElementById(lastActiveField.id + "-id").style.display =
            "block";
      }
    }
  }, [isAddingEditing, activeField]);

  return <div className="map-container" ref={mapContainer} />;
};

export default MainMap;
