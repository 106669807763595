import React, { useCallback, useContext, useState } from "react";
import { withRouter } from "react-router";

// Context providers / Utils
import networking from "../Util/Networking";
import WhiteLabelContext from "../Util/WhiteLabelContext";

// Styles
import "./InitialData.css";

const Sign = function SignUp({ history }) {
  const { app } = useContext(WhiteLabelContext);
  const [state, setState] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
  });

  const [submitEnabled, setSubmitEnabled] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignUp = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      const { email, name, lastname, company } = event.target.elements;
      try {
        // Call to backend API
        let payload = {
          email: email.value,
          name: name.value + " " + lastname.value,
          company: company.value,
        };
        networking
          .post("/api/v1/users/new_user", payload)
          .then((res) => res.data)
          .then((result) => {
            history.push(`/input_field/${result}`);
          })
          .catch((error) => {
            setLoading(false);
            alert("There was a problem with consulting endpoint.");
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        alert("There was a problem with your registration.");
        console.log(error);
      }
    },
    [history]
  );

  function areFieldsFilled(state) {
    let status = "enabled";
    Object.values(state).map((prop) => {
      if (prop === "") {
        status = "";
      }
    });
    return status;
  }

  function onChangeState(propName, value) {
    let newState = { ...state, [propName]: value };
    setState(newState);
    setSubmitEnabled(areFieldsFilled(newState));
  }

  return (
    <div className="sign-up__screen">
      <div className="sign-up">
        <div className="sign-up__container">
          <p className="sign-up__title">Payment Dashboard</p>
          {app === "ClimateAI" ? (
            <img
              alt="ClimateAI Logo"
              className="sign-up__logo climate"
              src="/Climate-AI-Color-Logo.png"
            />
          ) : (
            <img
              alt="Pacific Seeds Logo"
              className="sign-up__logo"
              src="/skip-logo.png"
            />
          )}
          <hr className="sign-up__separator"></hr>
          <h3 className="sign-up__content-title">Create an account</h3>
          <form onSubmit={handleSignUp} className="sign-up__form">
            <label className="sign-up__label first">First Name</label>
            <input
              className="sign-up__input"
              placeholder=""
              type="text"
              id="name"
              name="name"
              required
              value={state.name}
              onChange={(e) => {
                onChangeState("name", e.target.value);
              }}
              disabled={loading}
            ></input>
            <label className="sign-up__label">Last Name</label>
            <input
              className="sign-up__input"
              placeholder=""
              type="text"
              id="lastname"
              name="lastname"
              required
              value={state.lastname}
              onChange={(e) => {
                onChangeState("lastname", e.target.value);
              }}
              disabled={loading}
            ></input>
            <label className="sign-up__label">Company</label>
            <input
              className="sign-up__input"
              placeholder=""
              type="text"
              id="company"
              name="company"
              required
              value={state.company}
              onChange={(e) => {
                onChangeState("company", e.target.value);
              }}
              disabled={loading}
            ></input>
            <label className="sign-up__label">Email</label>
            <input
              className="sign-up__input"
              placeholder=""
              type="email"
              id="email"
              name="email"
              required
              value={state.email}
              onChange={(e) => {
                onChangeState("email", e.target.value);
              }}
              disabled={loading}
            ></input>
            <button
              type="submit"
              className={"sign-up__button" + (loading ? " loading" : "")}
              disabled={submitEnabled !== "enabled" || loading}
            >
              {!loading ? "Next" : "Loading..."}
            </button>
          </form>
          <div className="sign-up__bottom">
            {app !== "ClimateAI" && (
              <>
                <hr className="sign-up__separator"></hr>
                <span
                  className="sign-up__bottom-title"
                  onClick={() => window.open("https://climate.ai")}
                >
                  Powered by
                </span>
                <img
                  onClick={() => window.open("https://climate.ai")}
                  alt="ClimateAI Logo"
                  className="sign-up__bottom-logo"
                  src="/Climate-AI-Color-Logo.png"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sign);
