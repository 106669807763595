import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { useParams } from "react-router-dom";

// Context providers / Utils
import networking from "../Util/Networking";
import WhiteLabelContext from "../Util/WhiteLabelContext";

// Styles
import "./SuccessView.css";

const SuccessView = ({ history }) => {
  const { app } = useContext(WhiteLabelContext);
  const { user_id } = useParams();

  useEffect(() => {
    networking.post(`/api/v1/mailer/${user_id}`, {
      from: app,
    });
  }, []);

  return (
    <div className="success__screen">
      <div className="success__view">
        <div className="success__container">
          <div className="success__header">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM15 25.5L7.5 18L9.615 15.885L15 21.255L26.385 9.87L28.5 12L15 25.5Z" />
            </svg>
            <h3>Thank you for signing up!</h3>
          </div>
          <div className="success__content">
            <ul className="success__content-top">
              <li>
                <span>
                  {app === "ClimateAI" ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 17 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8.08057 10C6.98057 10 6.08057 9.1 6.08057 8C6.08057 6.9 6.98057 6 8.08057 6C9.18057 6 10.0806 6.9 10.0806 8C10.0806 9.1 9.18057 10 8.08057 10ZM14.0806 8.2C14.0806 4.57 11.4306 2 8.08057 2C4.73057 2 2.08057 4.57 2.08057 8.2C2.08057 10.54 4.03057 13.64 8.08057 17.34C12.1306 13.64 14.0806 10.54 14.0806 8.2ZM8.08057 0C12.2806 0 16.0806 3.22 16.0806 8.2C16.0806 11.52 13.4106 15.45 8.08057 20C2.75057 15.45 0.0805664 11.52 0.0805664 8.2C0.0805664 3.22 3.88057 0 8.08057 0Z" />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4 16H20V14H13V3H11L11 14H4V16Z" />
                      <path d="M15 11C17.7614 11 20 8.76142 20 6H19C16.2386 6 14 8.23857 14 11H15Z" />
                      <path d="M21 18H3V20H21V18Z" />
                      <path d="M9 8C6.23858 8 4 5.76142 4 3H5C7.76142 3 10 5.23858 10 8L9 8Z" />
                    </svg>
                  )}
                </span>
                <p className="success__container__text-long">
                  Your {app === "ClimateAI" ? "report" : "field(s)"} 
                  &nbsp;will be added within the next 3 business days to your account.
                </p>
              </li>
              <li>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 7.00001L19.5999 5.60001L8.9999 16.2Z" />
                  </svg>
                </span>
                <p>
                  We will notify you when your account is ready.
                </p>
              </li>
              <li>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z" />
                  </svg>
                </span>
                <p>
                  Purchase confirmation has been sent to your mail (check spam).
                </p>
              </li>
              {app === "ClimateAI" && <button>Back to My Reports</button>}
            </ul>
            <div
              className={
                "success__content-bottom" +
                (app === "ClimateAI" ? " climate" : "")
              }
            >
              {app === "ClimateAI" ? (
                <img className="climate" src="/Climate-AI-Color-Logo.png" />
              ) : (
                <img src="/skip-logo.png" />
              )}
              <p>For any questions please contact us</p>
              {app === "ClimateAI" ? (
                <a href="mailto:help@climate.ai">help@climate.ai</a>
              ) : (
                <a href="mailto:ps-info@advantaseeds.com">
                  ps-info@advantaseeds.com
                </a>
              )}
            </div>
          </div>
          {app !== "ClimateAI" && (
            <div
              onClick={() => window.open("https://climate.ai")}
              className="success__footer"
            >
              Powered by
              <img src="/Climate-AI-Color-Logo.png" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SuccessView);
