import React, { useState, useRef } from 'react'

import DropdownIcon from './icons/DropdownIcon'
import useOutsideComponentClickHandler from '../../../../Util/ClickOutsideHook'

import "./Dropdown.css"

function Dropdown({options = [], selection, search, selectedOptions}) {
    const [open, toggle] = useState(false)

    const wrapperRef = useOutsideComponentClickHandler(() => toggle(false))

    var availableOptions = options.filter(item => !selectedOptions.includes(item))

    function handleSelect(e) {
        // NOTE: I'm sorry for the verbosity, "e.target.value" returned undefined for some reason
        selection(e.target.attributes.value.nodeValue)
        toggle(false)
        e.target.value = ''
    }

    function validInput(input){
        // Checks for an empty input string
        if (sanitizeInput(input).replace(/\s/g, '') == '') return false
        
        // All good with this input string
        return true
    }

    function sanitizeInput(input){
        // Removes any special characters from the input string
        return input.replace(/[^a-z0-9 ]/gi, "")
    }

    function handleKeyDown(e) {
        // For when the user presses Enter instead of clicking on the dropdown;
        // Also covers the case where the crop is not on our croplist (user can't click it)
        if (e.key === 'Enter') {
            if (validInput(e.target.value)){
                selection(sanitizeInput(e.target.value))
                toggle(false)
                e.target.value = ''
            }
        }
    }

    function handleSearch(e) {
        toggle(true)
        search(e.target.value)
    }

    return (
        <div className="dropdown" ref={wrapperRef}>
            <div className="dropdown-button" onClick={() => toggle(!open)}>
                <input placeholder="Enter crop name" onChange={handleSearch} onKeyDown={handleKeyDown} />
                <span>
                    <DropdownIcon />
                </span>
            </div>
            <div className={`dropdown-content ${open ? "flex-column" : "hidden"}`}>
                {availableOptions.map(option => {
                    return (
                        <div 
                            className="dropdown-content-option" 
                            key={option}
                            value={option}
                            onClick={handleSelect}
                        >
                            {option}
                        </div>)
                })}
            </div>
        </div>
    )
}

export default Dropdown