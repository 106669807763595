import React, { useContext, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Dropdown from "../ui/Dropdown";
import WhiteLabelContext from "../../../../Util/WhiteLabelContext";

// Styles
import "./AddEditField.css";

const AddEditField = ({
  coords,
  onAddField,
  closeAddEditView,
  centerMap,
  activeField,
  deleteField,
  setMobileOpen,
}) => {
  const { app } = useContext(WhiteLabelContext);
  const [state, setState] = useState({
    id: activeField ? activeField.id : -1,
    name: activeField ? activeField.name : "",
    crop: activeField ? activeField.crop.split(", ") : [],
    region: activeField ? activeField.region : "",
  });
  const [availableOptions, setAvailableOptions] = useState([])

  const cropsOptions = [
    "Alfalfa",
    "Almonds",
    "Barley",
    "Canola",
    "Chickpeas",
    "Cotton",
    "Favabean",
    "Lupins",
    "Maize",
    "Mango",
    "Mungbean",
    "Potato",
    "Oats",
    "Onion",
    "Rice",
    "Sorghum",
    "Soybean",
    "Sugarbeet",
    "Tomato",
    "Wheat",
    "Yellow Field Peas",
  ];

  const filterOptions = () => {
    let newOptions = cropsOptions.filter(item => !state.crop.includes(item))
    console.log(state.crop)
    setAvailableOptions(newOptions)
  }

  const handleSelection = (option) => {
    setState({...state, crop: [...state.crop, option]})
    filterOptions()
  }

  const handleSearch = (option) => {
    let searchValue = option
    if (searchValue !== "") {
      searchValue = availableOptions.filter(item => String(item).toLowerCase().includes(String(searchValue).toLocaleLowerCase()))
      setAvailableOptions(searchValue)
    } else {
      filterOptions()
    }
  }

  const saveField = () => {
    if (!areFieldsFilled()) return;
    try {
      onAddField((previousState) => {
        const newField = {
          id: state.id !== -1 ? state.id : uuidv4(),
          name: state.name,
          crop: state.crop.join(", "),
          region: state.region,
          lat: coords["lat"],
          lon: coords["lon"],
        };
        closeAddEditView();
        resetForm();
        const newFields = [
          ...(activeField
            ? previousState.filter((field) => field.id !== state.id)
            : previousState),
          newField,
        ];
        centerMap(newFields);
        return newFields;
      });
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  const areFieldsFilled = () => {
    let result = true;
    Object.keys(state).map((key) => {
      if (key === "name" || key === "region") {
        if (state[key] === "") {
          result = false;
        }
      } else if (key === "crop") {
        if (state[key].length === 0) {
          result = false;
        }
      }
    });
    if (!coords["lat"] && !coords["lat"]) result = false;
    return result;
  };

  const onChangeState = (propName, value) => {
    let newState = { ...state, [propName]: value };
    setState(newState);
  };

  const resetForm = () => {
    setState({
      name: "",
      crop: [],
      region: "",
    });
  };

  useEffect(() => {
    setAvailableOptions(cropsOptions)
  }, [])

  return (
    <div className="field-input-layout__sidebar-content">
      <div className="field-form">
        <div className="field-form__container">
          <div
            className={
              "field-form__header" + (app === "ClimateAI" ? " climate" : "")
            }
          >
            <div className="field-form__header-left">
              <span
                onClick={() => {
                  closeAddEditView();
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                    fill="#666D74"
                  />
                </svg>
              </span>
              {activeField ? "Edit" : "Add new"}{" "}
              {app === "ClimateAI" ? "location" : "field"}
            </div>
            {activeField && (
              <span
                onClick={() => {
                  deleteField(activeField.id);
                  closeAddEditView();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
          </div>
          <div
            className={
              "field-form__input-group" + (app === "ClimateAI" ? " climate" : "")
            }
          >
            {app !== "ClimateAI" && <label>Location</label>}
            <div
              className="field-form__input-group-input"
              onClick={() => setMobileOpen(false)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 9.99999C9.08337 9.99999 8.33337 9.24999 8.33337 8.33332C8.33337 7.41666 9.08337 6.66666 10 6.66666C10.9167 6.66666 11.6667 7.41666 11.6667 8.33332C11.6667 9.24999 10.9167 9.99999 10 9.99999ZM15 8.49999C15 5.47499 12.7917 3.33332 10 3.33332C7.20837 3.33332 5.00004 5.47499 5.00004 8.49999C5.00004 10.45 6.62504 13.0333 10 16.1167C13.375 13.0333 15 10.45 15 8.49999ZM10 1.66666C13.5 1.66666 16.6667 4.34999 16.6667 8.49999C16.6667 11.2667 14.4417 14.5417 10 18.3333C5.55837 14.5417 3.33337 11.2667 3.33337 8.49999C3.33337 4.34999 6.50004 1.66666 10 1.66666Z"
                  fill="#666D74"
                />
              </svg>
              <span>
                Click on the map to {activeField !== null ? "edit" : "set"} location.
              </span>
            </div>
          </div>
          <div className="field-form__input-group">
            <label>
              {app === "ClimateAI" ? "Location Name" : "Field's Name"}
            </label>
            <input
              className={app === "ClimateAI" ? "climate" : ""}
              type="text"
              placeholder="Field name"
              value={state.name}
              onChange={(e) => {
                onChangeState("name", e.target.value);
              }}
            ></input>
          </div>
          <div className="field-form__input-group">
            <label>Region</label>
            <input
              className={app === "ClimateAI" ? "climate" : ""}
              type="text"
              placeholder="Field's region"
              value={state.region}
              onChange={(e) => {
                onChangeState("region", e.target.value);
              }}
            ></input>
          </div>
          <div className="field-form__input-group">
            <label>
              Select Crop(s)
            </label>
            <Dropdown options={availableOptions} selection={handleSelection} selectedOptions={state.crop} search={handleSearch} />
          </div>
          <div className="field-form__labels-container">
            {state.crop.length !== 0 && (<div className="field-form__input-group">
              <label>Selected Crop(s)</label>
              <div className="field-form__labels">
                {state.crop.map(crop => {
                  return (
                    <span
                      key={crop}
                      onClick={() => {
                          setState({
                            ...state,
                            crop: state.crop.filter(
                              (selectedCrop) => selectedCrop !== crop
                            ),
                          });
                      }}
                      className="field-form__selected-crop"
                    >
                      {crop}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                    </span>
                  )
                })}
              </div>
            </div>)}
          </div>
          <div
            onClick={saveField}
            className={
              "field-form__button" + (areFieldsFilled() ? "" : " disabled")
            }
          >
            Save {app === "ClimateAI" ? "Location" : "field"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditField;
