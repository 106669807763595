import React, { useState, useEffect, useCallback } from "react";
import { Prompt, useParams } from "react-router-dom";
import AddEditField from "./components/AddEditField/AddEditField";

import FieldList from "./components/FieldList/FieldList";
import MainMap from "./components/MainMap/MainMap";

// Styles
import "./FieldInputLayout.css";

const FieldInputLayout = () => {
  const [fields, setFields] = useState([]);
  const [isAddingEditing, setAddingEditing] = useState(true);
  const [coords, setCoords] = useState({
    lat: -26.4391,
    lon: 133.2813,
  });
  const [activeField, setActiveField] = useState(null);
  const [reportName, setReportName] = useState("");
  const [mapBounds, setMapBounds] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { id } = useParams();

  const closeAddEditView = () => {
    setAddingEditing(false);
    setActiveField(null);
    centerMap(fields);
  };

  const openAddEditView = () => {
    setAddingEditing(true);
    setMobileOpen(false);
  };

  const editField = (field) => {
    setAddingEditing(true);
    setActiveField(field);
    setCoords({ lat: field.lat, lon: field.lon });
  };

  const deleteField = (fieldId) => {
    const restFields = fields.filter((field) => field.id !== fieldId);
    centerMap(restFields);
    setFields(restFields);
  };

  const getFieldsBounds = (theFields) => {
    let top = theFields[0].lat;
    let left = theFields[0].lon;
    let bottom = theFields[0].lat;
    let right = theFields[0].lon;
    theFields.forEach((field) => {
      if (field.lon > right) right = field.lon;
      if (field.lon < left) left = field.lon;
      if (field.lat > top) top = field.lat;
      if (field.lat < bottom) bottom = field.lat;
    });
    const h = (top - bottom) / 6;
    const w = (right - left) / 6;
    return [
      [parseFloat(left) - w, parseFloat(bottom) - h],
      [parseFloat(right) + w, parseFloat(top) + h],
    ];
  };

  const centerMap = (theFields) => {
    if (theFields.length > 1) setMapBounds(getFieldsBounds(theFields));
  };

  const toggleMobileOpen = () => {
    setMobileOpen(!mobileOpen);
  };

  const alertUser = useCallback((e) => {
    e.preventDefault();
    return (e.returnValue = "You may have unsaved changes.");
  });

  useEffect(() => {
    if (fields.length > 0) {
      window.addEventListener("beforeunload", alertUser);
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [fields, alertUser]);

  return (
    <div className="field-input-layout">
      <Prompt
        when={fields.length > 0}
        message="Are you sure you want to leave? You have unsaved fields."
      />
      <div
        className={
          "field-input-layout__sidebar" + (mobileOpen ? " mobile-open" : "")
        }
      >
        <div
          className="field-input-layout__mobile-toggle"
          onClick={toggleMobileOpen}
        >
          {mobileOpen ? (
            <svg
              width="24"
              height="22"
              viewBox="0 0 24 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.59 7.79117L12 11.8427L7.41 7.79117L6 9.03848L12 14.3462L18 9.03848L16.59 7.79117Z"
                fill="#666D74"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
                fill="#666D74"
              />
            </svg>
          )}
        </div>
          {isAddingEditing ? (
            <AddEditField
              closeAddEditView={closeAddEditView}
              coords={coords}
              onAddField={setFields}
              centerMap={centerMap}
              activeField={activeField}
              deleteField={deleteField}
              setMobileOpen={setMobileOpen}
            />
          ) : (
            <FieldList
              fields={fields}
              openAddEditView={openAddEditView}
              editField={editField}
              user_id={id}
              removeEventListener={() =>
                window.removeEventListener("beforeunload", alertUser)
              }
              reportName={reportName}
              setReportName={setReportName}
            />
          )}
      </div>
      <div className="field-input-layout__map">
        <MainMap
          activeField={activeField}
          fields={fields}
          setCoords={setCoords}
          coords={coords}
          isAddingEditing={isAddingEditing}
          mapBounds={mapBounds}
        />
      </div>
    </div>
  );
};

export default FieldInputLayout;
