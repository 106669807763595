const themeApp = (whiteLabelVersion) => {
  if (whiteLabelVersion === "ClimateAI") {
    document.documentElement.style.setProperty("--color-primary", "#f47920");
    document.documentElement.style.setProperty(
      "--color-primary-opacity",
      "rgba(244, 121, 32, 0.3)"
    );
    document.documentElement.style.setProperty("--color-secondary", "#1c9690");
    document.documentElement.style.setProperty(
      "--color-success-icons",
      "#f47920"
    );
    document.documentElement.style.setProperty("--color-field-icon", "#666d74");
    document.documentElement.style.setProperty("--color-pills", "#1d2934");
    document.documentElement.style.setProperty(
      "--other-color-pills",
      "rgba(29, 41, 52, 0.1)"
    );
    document.documentElement.style.setProperty("--border-radius-pills", "4px");
  } else if (whiteLabelVersion === "Skip") {
    document.documentElement.style.setProperty("--color-primary", "#006DB5");
    document.documentElement.style.setProperty(
      "--color-primary-opacity",
      "rgba(0, 109, 181, 0.3)"
    );
    document.documentElement.style.setProperty("--color-secondary", "#1c9690");
    document.documentElement.style.setProperty(
      "--color-success-icons",
      "#1c9690"
    );
    document.documentElement.style.setProperty("--color-field-icon", "#1c9690");
    document.documentElement.style.setProperty("--color-pills", "#087dc2");
    document.documentElement.style.setProperty(
      "--other-color-pills",
      "rgba(8, 125, 194, 0.3)"
    );
    document.documentElement.style.setProperty("--border-radius-pills", "12px");
  }
};

export default themeApp;
