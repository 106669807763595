import React from "react"

const DropdownIcon = (props) => (
  <svg
    width={11}
    height={5}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m.5 0 5 5 5-5H.5Z" fill={props.fill || "#666D74"} />
  </svg>
)

export default DropdownIcon