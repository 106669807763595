import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";

// Context providers / Utils
import networking from "../../../../Util/Networking";
import { stripe_key, price_id, payment_mode } from "../../../../Util/config";
import WhiteLabelContext from "../../../../Util/WhiteLabelContext";

// Styles
import "./FieldList.css";
import { useLocation } from "react-router-dom";

const FieldList = ({
  fields,
  editField,
  openAddEditView,
  user_id,
  removeEventListener,
  reportName,
  setReportName,
}) => {
  const { app } = useContext(WhiteLabelContext);
  const [stripe, setStripe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [errors, setErrors] = useState({
    reportName: "",
    fields: "",
    terms: "",
  });

  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");

  useEffect(async () => {
    var stripe_temp = await loadStripe(stripe_key);
    setStripe(stripe_temp);
  }, []);

  const handleDebounceSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const debounceSearch = _.debounce(handleDebounceSearchInput, 500);

  const createCheckoutSession = function () {
    try {
      // Call to backend API
      setLoading(true);
      let payload = {
        user_id: user_id,
        email: email || "",
        fields: fields,
        domain: window.location.host,
        price_id: price_id,
        payment_mode: payment_mode,
      };
      networking
        .post("/api/v1/payment/create-checkout-session", payload)
        .then((res) => res.data)
        .then((result) => {
          removeEventListener();
          stripe.redirectToCheckout({
            sessionId: result.sessionId,
          });
          setLoading(false);
        })
        .catch((error) => {
          alert("There was a problem with consulting endpoint.");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      alert("There was a problem with the payment setup.");
      console.log(error);
      setLoading(false);
    }
  };

  const fieldsToShow = useMemo(() => {
    if (searchInput !== "") {
      return fields.filter((field) => {
        for (let _field in field) {
          if (
            field[_field] &&
            JSON.stringify(field[_field])
              .toLowerCase()
              .includes(searchInput.toLowerCase())
          )
            return true;
        }
      });
    }
    return [...fields];
  }, [fields, searchInput]);

  return (
    <div className="field-input-layout__sidebar-content --no-scroll">
      <div className="field-table">
          <div
            className={
              "field-table__logo-container" +
              (app === "ClimateAI" ? " climate" : "")
            }
          >
            {app === "ClimateAI" ? (
              <img
                alt="ClimateAI logo"
                className="field-table__logo climate"
                src="/Climate-AI-Color-Logo.png"
                onClick={() => {
                  if (fields.length > 0) {
                    if (
                      window.confirm(
                        "Are you sure you want to leave this page? You have unsaved fields."
                      )
                    )
                      window.location = "https://dashboard.climate.ai";
                    return;
                  }
                  window.location = "https://dashboard.climate.ai";
                }}
              />
            ) : (
              <img
                alt="Pacific Seeds Logo"
                className="field-table__logo"
                src="/skip-logo.png"
                onClick={() => {
                  if (fields.length > 0) {
                    if (
                      window.confirm(
                        "Are you sure you want to leave this page? You have unsaved fields."
                      )
                    )
                      window.location = "https://skip.climate.ai";
                    return;
                  }
                  window.location = "https://skip.climate.ai";
                }}
              />
            )}
          </div>
          <div className="field-table__header">
            {app === "Skip" ? (
              <h3 className="field-table__header-title">My Fields</h3>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Report Name"
                  className={
                    "field-table__header-input" +
                    (errors.reportName !== "" ? " report-input-error" : "")
                  }
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setErrors({
                        ...errors,
                        reportName: "Please name your report to continue.",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        reportName: "",
                      });
                    }
                    setReportName(event.target.value);
                  }}
                  value={reportName}
                />
              </>
            )}
            {app === "Skip" ? (
              <button
                className="field-table__header-button"
                onClick={() => {
                  openAddEditView();
                }}
              >
                <span>+</span>
                <div>Add field</div>
              </button>
            ) : (
              <span
                className="field-table__header-add-button"
                onClick={() => {
                  openAddEditView();
                }}
              >
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14 8.5H8V14.5H6V8.5H0V6.5H6V0.5H8V6.5H14V8.5Z" />
                </svg>
              </span>
            )}
          </div>

          {errors.reportName !== "" && (
            <div className="report-error">{errors.reportName}</div>
          )}
          <div
            className={
              "field-table__search" + (app === "ClimateAI" ? " climate" : "")
            }
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#666D74"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <input type="text" placeholder="Search" onKeyUp={debounceSearch} />
          </div>
          <ul className="field-table__list">
            {fields.length > 0 ? (
              fieldsToShow.length > 0 ? (
                fieldsToShow.map((field) => {
                  return (
                    <li
                      onClick={() => {
                        editField(field);
                      }}
                      key={field.id}
                      className="field-table__list-item"
                    >
                      <span className="field-table__list-icon">
                        {app === "ClimateAI" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 17 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8.08057 10C6.98057 10 6.08057 9.1 6.08057 8C6.08057 6.9 6.98057 6 8.08057 6C9.18057 6 10.0806 6.9 10.0806 8C10.0806 9.1 9.18057 10 8.08057 10ZM14.0806 8.2C14.0806 4.57 11.4306 2 8.08057 2C4.73057 2 2.08057 4.57 2.08057 8.2C2.08057 10.54 4.03057 13.64 8.08057 17.34C12.1306 13.64 14.0806 10.54 14.0806 8.2ZM8.08057 0C12.2806 0 16.0806 3.22 16.0806 8.2C16.0806 11.52 13.4106 15.45 8.08057 20C2.75057 15.45 0.0805664 11.52 0.0805664 8.2C0.0805664 3.22 3.88057 0 8.08057 0Z" />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M4 16H20V14H13V3H11L11 14H4V16Z" />
                            <path d="M15 11C17.7614 11 20 8.76142 20 6H19C16.2386 6 14 8.23857 14 11H15Z" />
                            <path d="M21 18H3V20H21V18Z" />
                            <path d="M9 8C6.23858 8 4 5.76142 4 3H5C7.76142 3 10 5.23858 10 8L9 8Z" />
                          </svg>
                        )}
                      </span>
                      <div>
                        <h5 className="field-table__list-item-name">
                          {field.name}
                        </h5>
                        <p className="field-table__list-item-crop">
                          {field.crop}
                        </p>
                        <p className="field-table__list-item-coords">
                          {app === "ClimateAI"
                            ? "Lat: " + field.lat + ", Lon: " + field.lon
                            : field.region + ", " + field.lat + ", " + field.lon}
                        </p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="field-table__list-item zero-fields">
                  There are no fields related to that search input.
                </li>
              )
            ) : (
              <li className="field-table__list-item zero-fields">
                Click on <span>+ {app === "Skip" ? "Add field" : ""}</span> to add
                fields to your
                {app === "ClimateAI" ? " report" : " new account."}
                {errors.fields !== "" && (
                  <div className="fields-error">{errors.fields}</div>
                )}
              </li>
            )}
          </ul>
        <div className="field-table__bottom-section">
          {app === "Skip" && (
            <>
              <div className="field-table__terms-conds">
                <input
                  id="terms"
                  type="checkbox"
                  value={agreed}
                  onChange={() => {
                    if (!agreed) {
                      setErrors({
                        ...errors,
                        terms: "",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        terms:
                          "You must accept the Terms & Conditions to continue.",
                      });
                    }
                    setAgreed(!agreed);
                  }}
                />
                <label htmlFor="terms">
                  I have read and agreed with the{" "}
                  <a
                    href="https://drive.google.com/file/d/1lEuAuh4fTwx22FKmodvPdSMqulxqrVph/view?usp=sharing"
                    target="_blank"
                  >
                    Terms & Conditions.
                  </a>
                </label>
              </div>
              {errors.terms !== "" && (
                <div className="terms-error">{errors.terms}</div>
              )}
            </>
          )}

          <div
            onClick={() => {
              if (
                fields.length === 0 ||
                loading ||
                (app === "Skip" && !agreed) ||
                (app === "ClimateAI" && !reportName)
              ) {
                let newErrors = {};
                if (fields.length === 0) {
                  newErrors = {
                    ...newErrors,
                    fields: "Please add locations to continue.",
                  };
                }
                if (app === "Skip" && !agreed) {
                  newErrors = {
                    ...newErrors,
                    terms: "You must accept the Terms & Conditions to continue.",
                  };
                }
                if (app === "ClimateAI" && !reportName) {
                  newErrors = {
                    ...newErrors,
                    reportName: "Please name your report to continue.",
                  };
                }
                setErrors({
                  ...errors,
                  ...newErrors,
                });
                return;
              }
              try {
                // Call to backend API
                setLoading(true);
                networking
                  .post(`/api/v1/fields/input_fields/${user_id}`, {
                    email: email || "",
                    fields: fields.map((field) => {
                      delete field.id;
                      return { ...field };
                    }),
                    report: app === "ClimateAI" ? reportName : "",
                  })
                  .then((res) => res.data)
                  .then((result) => {
                    createCheckoutSession();
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.log("There was a problem with consulting endpoint");
                    console.log(error);
                    setLoading(false);
                  });
              } catch (error) {
                alert(error);
                setLoading(false);
              }
            }}
            className={
              "field-table__payment-button" +
              (fields.length === 0 ||
              (app === "Skip" && !agreed) ||
              (app === "ClimateAI" && !reportName)
                ? " disabled"
                : "") +
              (loading ? " loading" : "")
            }
          >
            {!loading ? "Continue to payment" : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FieldList);
