export const local_backend_server =
  process.env.REACT_APP_STAGE === "local"
    ? "http://localhost:8001/"
    : process.env.REACT_APP_STAGE === "production"
    ? "https://pacific-seeds-payments-api-pipsbttbgq-uc.a.run.app/"
    : "https://pacific-seeds-payments-api-dev-pipsbttbgq-uc.a.run.app/";
export const stripe_key =
  process.env.REACT_APP_STAGE === "production"
    ? "pk_live_51Ij3SEGKTxmpauDofOdszS8l8CGQNrSdDqeWUr5kNCr6l5zBtAga8uqp9qKLEWs8X8qegmceUfcAgdoA0SCp0fjV00sjWZWEmO"
    : "pk_test_51Ij3SEGKTxmpauDo9y0tOjPICRWw8MkQYHwtYwIJhLy51OrLJtkdeHGPZuytgP3kkGr9c57lGHhRJ9c53gEGghCk00NR575ZUy";
export const price_id =
  process.env.REACT_APP_STAGE === "production"
    ? process.env.REACT_APP_STYLE.includes("climate")
      ? "price_1JeLk2GKTxmpauDopEMOIngP"
      : "price_1Jw9jHGKTxmpauDoF7zQRX7W"
    : process.env.REACT_APP_STYLE.includes("climate")
    ? "price_1JeLjHGKTxmpauDo4PseoAjr"
    : "price_1Jw9hAGKTxmpauDoMQhjTWoD";
export const payment_mode = process.env.REACT_APP_STYLE.includes("climate")
  ? "payment"
  : "subscription";

export const white_label_version = process.env.REACT_APP_STYLE.includes(
  "climate"
)
  ? "ClimateAI"
  : "Skip";
