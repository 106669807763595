import React, { useEffect } from "react";

// Modules
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// components
import InitialData from "./components/InitialData";
import SuccessView from "./components/SuccessView";
import FieldInputLayout from "./components/FieldInput/FieldInputLayout";

// Styles
import "./App.css";
import WhiteLabelContext from "./Util/WhiteLabelContext";
import { white_label_version } from "./Util/config";
import themeApp from "./Util/theme";

function App() {
  themeApp(white_label_version);

  useEffect(() => {
    if (white_label_version === "ClimateAI") {
      document.title = "Long Term Payment Dashboard";
      const favicon = document.getElementById("favicon");
      favicon.href = "/favicon.ico";
      const appleFavicon = document.getElementById("apple-favicon");
      appleFavicon.href = "/favicon.ico";
    } else {
      document.title = "Pacific Seeds Payment";
      const favicon = document.getElementById("favicon");
      favicon.href = "/skip-logo-image.png";
      const appleFavicon = document.getElementById("apple-favicon");
      appleFavicon.href = "/skip-logo-image.png";
    }
  }, []);

  return (
    <WhiteLabelContext.Provider value={{ app: white_label_version }}>
      <Router>
        <Switch>
          <Route exact path="/">
            <InitialData />
          </Route>
          <Route path="/input_field/:id">
            <FieldInputLayout />
          </Route>
          <Route path="/payment/:user_id/:session_id">
            <SuccessView />
          </Route>
        </Switch>
      </Router>
    </WhiteLabelContext.Provider>
  );
}

export default App;
